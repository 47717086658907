import React from 'react';
import { graphql } from 'gatsby';
import BasicLayout from 'src/components/layouts/BasicLayout';
import { H1, A, Strong, prettyColors, getFaintIconColorClass } from 'src/DesignSystem';
import { Link } from 'gatsby';
import ReactMarkdown from 'react-markdown';
import SEO from 'src/components/SEO';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowAltRight, faDragon, faDiceD20 } from '@fortawesome/pro-solid-svg-icons';
import clsx from 'clsx';

interface Node {
  node: {
    id: string;
    internal: {
      content: string;
    };
    frontmatter: {
      title: string;
      subtitle: string;
      description: string;
      slug: string;
    };
  };
}

interface Props {
  data: {
    allMdx: {
      edges: Node[];
    };
  };
}

export default function Adventures(props: Props): JSX.Element {
  return (
    <BasicLayout>
      <SEO title="Adventures" description="Adventures for 5e and other games" />
      <div className="max-w-3xl p-4 mx-auto text-gray-900 mb-8">
        <span className="relative">
          <div className="absolute bg-purple-200 h-6 w-full rounded top-0 z-0" />
          <H1 className="relative inline z-10 mx-2">Adventures</H1>
        </span>
        <ul>
          {props.data.allMdx.edges.map((post: Node) => {
            return (
              <li key={post.node.id} className="border-b-2 py-4">
                <Link to={post.node.frontmatter.slug}>
                  <h2 className="text-lg font-medium mb-2 mt-1">{post.node.frontmatter.title}</h2>
                </Link>
                {post.node.frontmatter.description ? (
                  <ReactMarkdown
                    className="text-gray-600 mb-2"
                    renderers={{
                      link: A,
                      strong: Strong,
                    }}
                  >
                    {post.node.frontmatter.description}
                  </ReactMarkdown>
                ) : (
                  <div className="text-center">
                    {prettyColors.map((color, index) => (
                      <FontAwesomeIcon
                        className={clsx('mr-2', 'text-xl', getFaintIconColorClass(color))}
                        icon={index % 2 ? faDragon : faDiceD20}
                        key={color}
                      />
                    ))}
                  </div>
                )}

                <div className="text-right">
                  <Link
                    className={clsx(
                      'py-1',
                      'px-2',
                      'mr-2',
                      'rounded',
                      'font-medium',
                      'text-purple-600',
                      'hover:bg-purple-100'
                    )}
                    to={post.node.frontmatter.slug}
                  >
                    Go to adventure
                    <FontAwesomeIcon className="ml-2" icon={faLongArrowAltRight} />
                  </Link>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </BasicLayout>
  );
}

export const pageQuery = graphql`
  query {
    allMdx(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { slug: { glob: "/adventures/*" } } }
    ) {
      edges {
        node {
          id
          internal {
            content
          }
          frontmatter {
            title
            subtitle
            description
            slug
          }
        }
      }
    }
  }
`;
